import { AxiosInstance, AxiosResponse } from 'axios';
import apiClient from '../../ApiClient';
import { ApiResponse, SwitchRolePayload } from '../../../Types';

class AdminService {
    private client: AxiosInstance;

    constructor() {
        this.client = apiClient();
    }

    private handleError(error: any): never {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || "An unexpected issue occurred. Please try again later.";
            throw new Error(errorMessage);
        } else if (error.message) {
            throw new Error(error.message);
        } else {
            throw new Error("An unexpected issue occurred. Please try again later.");
        }
    }

    async getAllAdmins(): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = await this.client.get('/user/admins');
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    async switchRole(payload: SwitchRolePayload): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = await this.client.post('/user/admins/assign-role', payload);
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    async fetchAdminRole(): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = await this.client.get('/user/admins/fetch/role');
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }

    async fetchUserRole(): Promise<ApiResponse> {
        try {
            const response: AxiosResponse<ApiResponse> = await this.client.get('/user/fetch/role');
            return response.data;
        } catch (error: any) {
            this.handleError(error);
        }
    }
}

export default AdminService;
